<template>
  <g>
    <svg:style>

      .cls-6 {
        fill: url(#Bottom_Gradient_2);
      }

      .cls-7 {
        fill: url(#LR_Gradient_2);
      }

      .cls-8 {
        fill: url(#LR_Gradient_2-2);
      }

      .cls-9 {
        fill: url(#Top_Gradient_2);
      }

      .cls-10 {
        fill: url(#Bottom_Gradient_2-2);
      }

      .cls-11 {
        fill: url(#LR_Gradient_2-3);
      }

      .cls-12 {
        fill: url(#LR_Gradient_2-4);
      }

      .cls-13 {
        fill: url(#Top_Gradient_2-2);
      }

      .cls-14 {
        fill: url(#Bottom_Gradient_2-3);
      }

      .cls-15 {
        fill: url(#LR_Gradient_2-5);
      }

      .cls-16 {
        fill: url(#LR_Gradient_2-6);
      }

      .cls-17 {
        fill: url(#Top_Gradient_2-3);
      }

      .cls-18 {
        fill: url(#Bottom_Gradient_2-4);
      }

      .cls-19 {
        fill: url(#LR_Gradient_2-7);
      }

      .cls-20 {
        fill: url(#LR_Gradient_2-8);
      }

      .cls-21 {
        fill: url(#Top_Gradient_2-4);
      }

      .cls-22 {
        fill: url(#Bottom_Gradient_2-5);
      }

      .cls-23 {
        fill: url(#LR_Gradient_2-9);
      }

      .cls-24 {
        fill: url(#LR_Gradient_2-10);
      }

      .cls-25 {
        fill: url(#Top_Gradient_2-5);
      }
    </svg:style>
    <linearGradient
      id="LR_Gradient_2"
      data-name="LR Gradient 2"
      :x1="doorLeftWidth1 + 7846.55"
      :y1="doorTopHeight1 + 7385.67"
      :x2="doorLeftWidth1 + 7846.55"
      :y2="doorTopHeight1 + 7377.31"
      gradientTransform="translate(-7355.49 8071.48) rotate(-90)"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#fff" stop-opacity="0.3"/>
      <stop offset="0.01" stop-color="#e9e9e9" stop-opacity="0.3"/>
      <stop offset="0.02" stop-color="#ababac" stop-opacity="0.3"/>
      <stop offset="0.03" stop-color="#454647" stop-opacity="0.3"/>
      <stop offset="0.04" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="0.58" stop-color="#010204" stop-opacity="0.1"/>
      <stop offset="0.89" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="1" stop-color="#010204" stop-opacity="0.6"/>
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2-2"
      :x1="doorLeftWidth1 + 55.48"
      :y1="doorTopHeight1 + 225.04"
      :x2="doorLeftWidth1 + 63.86"
      :y2="doorTopHeight1 + 225.04"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="Top_Gradient_2"
      data-name="Top Gradient 2"
      x1="42.93"
      y1="186.93"
      x2="42.93"
      y2="176.38" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#fff" stop-opacity="0.6"/>
      <stop offset="0" stop-color="#fff" stop-opacity="0.56"/>
      <stop offset="0" stop-color="#fff" stop-opacity="0.44"/>
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.33"/>
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.25"/>
      <stop offset="0.02" stop-color="#fff" stop-opacity="0.18"/>
      <stop offset="0.03" stop-color="#fff" stop-opacity="0.13"/>
      <stop offset="0.04" stop-color="#fff" stop-opacity="0.11"/>
      <stop offset="0.06" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.26" stop-color="#fff" stop-opacity="0.04"/>
      <stop offset="0.47" stop-color="#fff" stop-opacity="0"/>
      <stop offset="0.87" stop-color="#010204" stop-opacity="0.2"/>
      <stop offset="1" stop-color="#010204" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient
      id="Bottom_Gradient_2-2"
      :x1="doorLeftWidth1 + 1253.99"
      :y1="doorTopHeight1 + 13781.81"
      :x2="doorLeftWidth1 + 1253.99"
      :y2="doorTopHeight1 + 13773.28"
      xlink:href="#Bottom_Gradient_2"/>
    <linearGradient
      id="LR_Gradient_2-3"
      :x1="doorLeftWidth1 + 7846.55"
      :y1="doorTopHeight1 + 7439.43"
      :x2="doorLeftWidth1 + 7846.55"
      :y2="doorTopHeight1 + 7431.07"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="LR_Gradient_2-4"
      :x1="doorLeftWidth1 + 109.24"
      :y1="doorTopHeight1 + 225.04"
      :x2="doorLeftWidth1 + 117.62"
      :y2="doorTopHeight1 + 225.04"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="Top_Gradient_2-2"
      :x1="doorLeftWidth1 + 96.69"
      :y1="doorTopHeight1 + 184.93"
      :x2="doorLeftWidth1 + 96.69"
      :y2="doorTopHeight1 + 176.38"
      xlink:href="#Top_Gradient_2"/>
    <linearGradient
      id="Bottom_Gradient_2-3"
      :x1="doorLeftWidth1 + 1307.75"
      :y1="doorTopHeight1 + 13936.04"
      :x2="doorLeftWidth1 + 1307.75"
      :y2="doorTopHeight1 + 13927.51"
      xlink:href="#Bottom_Gradient_2"/>
    <linearGradient
      id="LR_Gradient_2-5"
      :x1="doorLeftWidth1 + 8001.38"
      :y1="doorTopHeight1 + 7385.67"
      :x2="doorLeftWidth1 + 8001.38"
      :y2="doorTopHeight1 + 7377.31"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="LR_Gradient_2-6"
      :x1="doorLeftWidth1 + 55.48"
      :y1="doorTopHeight1 + 70.21"
      :x2="doorLeftWidth1 + 63.86"
      :y2="doorTopHeight1 + 70.21"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="Top_Gradient_2-3"
      :x1="doorLeftWidth1 + 42.93"
      :y1="doorTopHeight1 + 29.49"
      :x2="doorLeftWidth1 + 42.93"
      :y2="doorTopHeight1 + 20.94"
      xlink:href="#Top_Gradient_2"/>
    <linearGradient
      id="Bottom_Gradient_2-4"
      :x1="doorLeftWidth1 + 1253.99"
      :y1="doorTopHeight1 + 13936.04"
      :x2="doorLeftWidth1 + 1253.99"
      :y2="doorTopHeight1 + 13927.51"
      xlink:href="#Bottom_Gradient_2"/>
    <linearGradient
      id="LR_Gradient_2-7"
      :x1="doorLeftWidth1 + 8001.38"
      :y1="doorTopHeight1 + 7439.43"
      :x2="doorLeftWidth1 + 8001.38"
      :y2="doorTopHeight1 + 7431.07"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="LR_Gradient_2-8"
      :x1="doorLeftWidth1 + 109.24"
      :y1="doorTopHeight1 + 70.21"
      :x2="doorLeftWidth1 + 117.62"
      :y2="doorTopHeight1 + 70.21"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="Top_Gradient_2-4"
      :x1="doorLeftWidth1 + 96.69"
      :y1="doorTopHeight1 + 29.49"
      :x2="doorLeftWidth1 + 96.69"
      :y2="doorTopHeight1 + 20.94"
      xlink:href="#Top_Gradient_2"/>
    <linearGradient
      id="Bottom_Gradient_2-5"
      :x1="doorLeftWidth1 + 1280.88"
      :y1="doorTopHeight1 + 13891.15"
      :x2="doorLeftWidth1 + 1280.88"
      :y2="doorTopHeight1 + 13882.61"
      xlink:href="#Bottom_Gradient_2"/>
    <linearGradient
      id="LR_Gradient_2-9"
      :x1="doorLeftWidth1 + 7923.87"
      :y1="doorTopHeight1 + 7385.65"
      :x2="doorLeftWidth1 + 7923.87"
      :y2="doorTopHeight1 + 7377.29"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="LR_Gradient_2-10"
      :x1="doorLeftWidth1 + 109.24"
      :y1="doorTopHeight1 + 147.72"
      :x2="doorLeftWidth1 + 117.62"
      :y2="doorTopHeight1 + 147.72"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="Top_Gradient_2-5"
      :x1="doorLeftWidth1 + 69.81"
      :y1="doorTopHeight1 + 139.62"
      :x2="doorLeftWidth1 + 69.81"
      :y2="doorTopHeight1 + 131.07"
      xlink:href="#Top_Gradient_2"/>
    <g id="C23">
      <g id="Panels">
        <g id="Panel_BR" data-name="Panel BR">
          <polygon
            id="Shadow"
            class="cls-6"
            style="fill: rgba(0, 0, 0, .15)"
            :points="`${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 55.48} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 63.86} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 264.96}`"/>
          <polygon
            id="Panel_L"
            data-name="Panel L"
            class="cls-7"
            :points="`${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 184.93} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 176.38} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 184.93}`"/>
          <polygon
            id="Panel_R"
            data-name="Panel R"
            class="cls-8"
            :points="`${doorLeftWidth1 + 55.5} ${doorTopHeight1 + 185.13} ${doorLeftWidth1 + 55.48} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 63.86} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 63.86} ${doorTopHeight1 + 176.58} ${doorLeftWidth1 + 55.5} ${doorTopHeight1 + 185.13}`"/>
          <polygon
            id="Highlight"
            class="cls-9"
            :points="`${doorLeftWidth1 + 55.7} ${doorTopHeight1 + 184.93} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 184.93} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 176.38} ${doorLeftWidth1 + 64.05} ${doorTopHeight1 + 176.38} ${doorLeftWidth1 + 55.7} ${doorTopHeight1 + 184.93}`"/>
        </g>
        <g id="Panel_BL" data-name="Panel BL">
          <polygon
            id="Shadow-2"
            data-name="Shadow"
            class="cls-10"
            style="fill: rgba(0, 0, 0, .15)"
            :points="`${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 109.24} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 117.61} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 75.58} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 264.96}`"/>
          <polygon
            id="Panel_L-2"
            data-name="Panel L"
            class="cls-11"
            :points="`${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 184.93} ${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 75.58} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 75.58} ${doorTopHeight1 + 176.38} ${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 184.93}`"/>
          <polygon
            id="Panel_R-2"
            data-name="Panel R"
            class="cls-12"
            :points="`${doorLeftWidth1 + 109.26} ${doorTopHeight1 + 185.13} ${doorLeftWidth1 + 109.24} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 117.61} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 117.61} ${doorTopHeight1 + 176.58} ${doorLeftWidth1 + 109.26} ${doorTopHeight1 + 185.13}`"/>
          <polygon
            id="Highlight-2"
            data-name="Highlight"
            class="cls-13"
            :points="`${doorLeftWidth1 + 109.46} ${doorTopHeight1 + 184.93} ${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 184.93} ${doorLeftWidth1 + 75.58} ${doorTopHeight1 + 176.38} ${doorLeftWidth1 + 117.81} ${doorTopHeight1 + 176.38} ${doorLeftWidth1 + 109.46} ${doorTopHeight1 + 184.93}`"/>
        </g>
        <g id="Panel_TR" data-name="Panel TR">
          <polygon
            id="Shadow-3"
            data-name="Shadow"
            class="cls-14"
            style="fill: rgba(0, 0, 0, .15)"
            :points="`${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 110.73} ${doorLeftWidth1 + 55.48} ${doorTopHeight1 + 110.73} ${doorLeftWidth1 + 63.86} ${doorTopHeight1 + 119.26} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 119.26} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 110.73}`"/>
          <polygon
            id="Panel_L-3"
            data-name="Panel L"
            class="cls-15"
            :points="`${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 29.49} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 110.73} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 119.26} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 20.94} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 29.49}`"/>
          <polygon
            id="Panel_R-3"
            data-name="Panel R"
            class="cls-16"
            :points="`${doorLeftWidth1 + 55.5} ${doorTopHeight1 + 29.7} ${doorLeftWidth1 + 55.48} ${doorTopHeight1 + 110.73} ${doorLeftWidth1 + 63.86} ${doorTopHeight1 + 119.26} ${doorLeftWidth1 + 63.86} ${doorTopHeight1 + 21.15} ${doorLeftWidth1 + 55.5} ${doorTopHeight1 + 29.7}`"/>
          <polygon
            id="Highlight-3"
            data-name="Highlight"
            class="cls-17"
            :points="`${doorLeftWidth1 + 55.7} ${doorTopHeight1 + 29.49} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 29.49} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 20.94} ${doorLeftWidth1 + 64.05} ${doorTopHeight1 + 20.94} ${doorLeftWidth1 + 55.7} ${doorTopHeight1 + 29.49}`"/>
        </g>
        <g id="Panel_TL" data-name="Panel TL">
          <polygon
            id="Shadow-4"
            data-name="Shadow"
            class="cls-6"
            style="fill: rgba(0, 0, 0, .15)"
            :points="`${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 110.73} ${doorLeftWidth1 + 109.24} ${doorTopHeight1 + 110.73} ${doorLeftWidth1 + 117.61} ${doorTopHeight1 + 119.26} ${doorLeftWidth1 + 75.58} ${doorTopHeight1 + 119.26} ${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 110.73}`"/>
          <polygon
            id="Panel_L-4"
            data-name="Panel L"
            class="cls-19"
            :points="`${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 29.49} ${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 110.73} ${doorLeftWidth1 + 75.58} ${doorTopHeight1 + 119.26} ${doorLeftWidth1 + 75.58} ${doorTopHeight1 + 20.94} ${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 29.49}`"/>
          <polygon
            id="Panel_R-4"
            data-name="Panel R"
            class="cls-20"
            :points="`${doorLeftWidth1 + 109.26} ${doorTopHeight1 + 29.7} ${doorLeftWidth1 + 109.24} ${doorTopHeight1 + 110.73} ${doorLeftWidth1 + 117.61} ${doorTopHeight1 + 119.26} ${doorLeftWidth1 + 117.61} ${doorTopHeight1 + 21.15} ${doorLeftWidth1 + 109.26} ${doorTopHeight1 + 29.7}`"/>
          <polygon
            id="Highlight-4"
            data-name="Highlight"
            class="cls-21"
            :points="`${doorLeftWidth1 + 109.46} ${doorTopHeight1 + 29.49} ${doorLeftWidth1 + 83.93} ${doorTopHeight1 + 29.49} ${doorLeftWidth1 + 75.58} ${doorTopHeight1 + 20.94} ${doorLeftWidth1 + 117.81} ${doorTopHeight1 + 20.94} ${doorLeftWidth1 + 109.46} ${doorTopHeight1 + 29.49}`"/>
        </g>
        <g id="Panel_Middle" data-name="Panel Middle">
          <polygon
            id="Shadow-5"
            data-name="Shadow"
            class="cls-22"
            style="fill: rgba(0, 0, 0, .15)"
            :points="`${doorLeftWidth1 + 30.16} ${doorTopHeight1 + 155.62} ${doorLeftWidth1 + 109.24} ${doorTopHeight1 + 155.62} ${doorLeftWidth1 + 117.61} ${doorTopHeight1 + 164.16} ${doorLeftWidth1 + 21.8} ${doorTopHeight1 + 164.16} ${doorLeftWidth1 + 30.16} ${doorTopHeight1 + 155.62}`"/>
          <polygon
            id="Panel_L-5"
            data-name="Panel L"
            class="cls-23"
            :points="`${doorLeftWidth1 + 30.16} ${doorTopHeight1 + 139.62} ${doorLeftWidth1 + 30.16} ${doorTopHeight1 + 155.62} ${doorLeftWidth1 + 21.8} ${doorTopHeight1 + 164.16} ${doorLeftWidth1 + 21.8} ${doorTopHeight1 + 131.07} ${doorLeftWidth1 + 30.16} ${doorTopHeight1 + 139.62}`"/>
          <polygon
            id="Panel_R-5"
            data-name="Panel R"
            class="cls-24"
            :points="`${doorLeftWidth1 + 109.26} ${doorTopHeight1 + 139.83} ${doorLeftWidth1 + 109.24} ${doorTopHeight1 + 155.62} ${doorLeftWidth1 + 117.61} ${doorTopHeight1 + 164.16} ${doorLeftWidth1 + 117.61} ${doorTopHeight1 + 131.27} ${doorLeftWidth1 + 109.26} ${doorTopHeight1 + 139.83}`"/>
          <polygon
            id="Highlight-5"
            data-name="Highlight"
            class="cls-25"
            :points="`${doorLeftWidth1 + 109.46} ${doorTopHeight1 + 139.62} ${doorLeftWidth1 + 30.16} ${doorTopHeight1 + 139.62} ${doorLeftWidth1 + 21.8} ${doorTopHeight1 + 131.07} ${doorLeftWidth1 + 117.81} ${doorTopHeight1 + 131.07} ${doorLeftWidth1 + 109.46} ${doorTopHeight1 + 139.62}`"/>
        </g>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
